.back {
  background-color: white;
}
.placeorder__image {
  
  height: 500px;
  /* padding-right: 100px;
  padding-left: -100px; */

  /* margin-left: 100; */
  /* margin-left: -10;
    margin: 100px 150px; */
}
.placeholder__description {
   margin-top: -450px;
  padding-left: 500px; 
   /* display: -webkit-box;  */
 /* -webkit-box-orient: vertical;  
  overflow: hidden;
 -webkit-line-clamp: 2; 
  margin-bottom: 30px;   */
}
.textgap {
  margin: 20px;
  background-color: #b1270400;
}
.pricetag {
  font-size: 24px;
  color: #b12704;
}
.placeorder__order {
  margin-top: -500px;

  padding-left: 1100px;
  max-width: 600px;

  /* width: 230px;
    height: 230px; */
}
.placeorder__button {
  width: 200px !important;
  height: 40px;
  border-radius: 40px;
  margin: 5px;
  border: 0px;
  color: black;
}
.description-container {
  max-height: 122px; /* 22px (line height) * 2 (number of lines) */
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: Adds ellipsis (...) when text overflows */
   white-space: nowrap;  
}

.buynow {
  background-color: #ffd712;
}
.addtocart {
  background-color: #ffa41b;
  margin-top: 30px !important;
}
.container-t{
  padding: 42px;
}
.related-title{
  display: flex;
  height: 10px;
  margin-top: 90px;
}
