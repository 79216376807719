.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 5px;
  padding: 5px;
  width: 100%;
  max-height: 300px;
  min-width: 100px;
   /* background-color: rgba(255, 255, 255, 0);  */
  z-index: 1;
  position: relative;
  top:-350px;
}
.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.txtdescription {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  }
.product__rating {
  display: flex;  
}
.containerhome{    
  position: relative;
  top:1px;
  align-items: center;
  margin-left: 3%;
  padding: 5px;
  justify-content: flex-end;
}
.bg__{
  background-color: #ffffff;
}
.txt_{
  padding-left: 4%;
  position: relative;
  top:-8px;
  font-size: small;
}
.title___{
  padding-left: 4%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 225%;
  color: #e47911;
  letter-spacing: -0.03em;
}
.bxc-grid__image img {
  vertical-align: top;
max-width: 100%!important;
    border: 0;
-webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-clip-margin: content-box;
    overflow: clip;
}
.col-lg-3{
margin: 5px;
  padding: 5px;
  width: 100%;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.product__price {
  margin-top: 5px;
}

.product__info {
  height: 100px;
  margin-bottom: 15px;
}