

/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04AA6D;
  color: white;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
  }
  
.containers {
  position: fixed;
  bottom: 100px;
  right: 60px;
  cursor: pointer;
}

.chatbot-icon {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  transition: background 0.3s;
  position: fixed;
  bottom: 70px;
  right: 70px;
  cursor: pointer;
}

.chatbot-icon img {
  width: 80px;
  height: 80px;
}
.react-chatbot-kit-chat-container{
  width: 70%;
}
.react-chatbot-kit-chat-bot-message{
  margin-left: 0;
  cursor: pointer;
}
.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #bcebc3;
  font-family: Arial;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 20.5px;
  font-weight: normal;
}