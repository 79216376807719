.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .footer-links li {
    margin-bottom: 0.5rem;
  }

  .footer-links a {
    color: #a2a2a2;
    text-decoration: none;
    font-weight: 500;
  }

  .footer-links a:hover {
    color: #007bff;
  }
  .footerh5{
    color: #ffffff;
  }