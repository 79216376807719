@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0 40px;
}
.rating li,
  .num-display {
  position: relative;
  background: #f4f4f4;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
}
.rating li label {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.rating li:hover,
.num-display {
  background: #0c8528;
  color: #fff;
}
[type='radio'] {
  opacity: 0;
}
[type='radio']:checked~label {
  background: #0c8528;
  color: #fff;
}
.input-group {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  padding: 8px 10px;
  border-radius: 8px;
}
input {
  flex-grow: 2;
  border: none;
  font-size: 16px;
}
input:focus {
  outline: none;
}


@media (max-width: 400px) {
.rating li {
  margin: 10px 3px;
}
.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0 40px;
}
.input-group input {
  width: 80%;
 }
}