.header__search {
  display: flex;
  align-items: center;
  border-radius: 24px;
  width: 50%;
  height: 40px;
}
/* .header__searchIcon{
    color: #181f29;
} */
.header__searchIcon {
    padding: 0px;
    height: 100% !important;
    width: 45px !important;
    background-color: orange;
    border-radius: 0 4px 4px 0;
  }

*{
  box-sizing: border-box;
  margin: 0;
}
.dropdown {
  background-color:white;
}
/* .header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 10;
  height: 80px;
  background: #131921;
  height: 60px;
} */

.header__logo {
  width: 100px;
  height: 50px;
  margin: 0px 10px 0px 10px;
  object-fit: contain;
  padding-top: 11px;
}

.header__locationOption{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: white;
  width: 14vw;
  margin-left: 10px;
}

.header__locationOptionLineTwo{
  font-weight: 700;
  font-size: clamp(12px, 1vw, 14px);
}


.header__searchForm {
  display: flex;
  width: 100%;
  height: 40px;
}

.header__selectInput{
  position: relative;
  display: flex;
  background-color:#f1f3f5;
  border-radius: 5prgb(186, 25, 41)px;
  border-right: 1px solid #dee2e6;
}

.header__selectInputText{
font-weight: 500;
padding: 5px;
padding-left: 8px;
margin-top: 3px;
width: max-content;
color: #495057;
font-size: 14px;
}

.header__selectInputIcon{
  margin-top: 6px;
  color: #495057;
}

.header__selectInput select{
  /* position: absolute; */
  background-color: white;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
}

.header__searchInput {
  width: 100%;
  
  height: 40px;
  border: none;
  font-size: clamp(12px, 1vw, 1px);
  z-index: 10;
}

.header__searchInput:focus {
  outline: none;
  background-color:white;
}



.header__nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}


/* .header__right{
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
}

.header__option {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 10vw;
  color: white;
  margin-left: 10px;
  }
.br-2:hover{
  border:0.5px solid white;
  width: max-content;
  padding:0.125rem;
}

.relative{
  position: relative;
}

.nav_arrow{
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: white;
  position: absolute;
  height: 8px;
  width: 16px;
  top: 50px;
  left: 55px;
}
.box{
  width: 177px;
    height: 75px;
    background-color: white;
    position: absolute;
    left: -23px;
    top: 57px;
    box-shadow: 2px 3px 9px 0px rgba(0,0,0,0.75);
}
.signupbtn{
    background: rgb(237, 171, 50);
    width: 50%;
    margin: 0 auto;
    text-align: center;
    margin-top: 9px;
    padding: 0.25rem;
}
.signupbtn:hover{
  background: rgb(252, 165, 3);
  box-shadow: 0 2px 4px rgb(237, 171, 50);
}
.text{
  color: rgb(128, 128, 128);
    font-size: 0.75rem;
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
}
.link{
  color: #0008ff;
  font-weight: 700;
  margin-left: 5px;
  cursor: pointer;
}
.link:hover{
  border-bottom: 1px solid blue;
}
.header__option a:hover, a:visited, a:link, a:active{
  text-decoration: none;
}

.header__optionLineOne{
  font-size: 12px;
}

.header__optionLineTwo {
  font-weight: 700;
  font-size: clamp(12px, 1.5vw, 18px);
  cursor: pointer;
}

.header__checkout{
  display: flex;
  align-items: flex-end;
}

.header__checkout a:hover, a:visited, a:link, a:active{
  text-decoration: none;
  color: white;
} */

/* .header__optionBasket {
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  width: 40px;
  height: 26px;
} */

/* .header__basketCount {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: -0.5vw;
  font-weight: bold;
  color:#f08804;
} */

/* .header__basketText{
  font-weight: 700;
  font-size: clamp(12px, 1vw, 16px);
} */

@media (max-width: 650px){
  .header{
    flex-flow: row wrap;
    justify-content: space-between;
    height: auto;
  }
}