section{
    position: relative;
    width: 100%;
    top: 60px;
    min-height:100vh;   
}

.sign_container{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign_header img{
    width: 100px;
    margin-top: -113px;
    cursor: pointer;
}

.sign_form{
    border: 1px solid #c9c9c9;
    padding: 10px 30px;
    margin-top: -50px;
    border-radius: 5px;
}

.sign_form h1{
    font-weight: 500;
    font-size: 27px;
    margin: 10px 0;
}

.sign_form label{
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 1px;
}

.sign_form input{
    width: 100%;
    height: 31px;
    padding: 3px 7px;
    border-radius: 3px;
    outline: none;
    border:1px solid #a6a6a6;
    margin-bottom: 10px;
    margin-top: 1px;
}

.sign_form .signin_btn{
    margin-top: 7px;
    margin-bottom: 23px;
    width: 100%;
    background: #f3d078;
    cursor: pointer;
    border:1px solid #9c7e31;
    height: 29px;
    outline: none;
    border-radius: 3px;
    color: #111;
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b)
}

.sign_form input:focus{
    box-shadow: 0 0 5px 0px #ffbc2c;
}

.sign_form .signin_info{
    margin-top: 23px;
    display: flex;
    margin-bottom: 10px;
}

.sign_form .signin_info p{
    font-size: 13px;
    font-weight: 600;
    color: #222;
}


.sign_form .signin_info a{
    font-size: 13px;
    letter-spacing: .5px;
    margin-left: 5px;
    color: #0066c0;
    text-decoration: none;
    font-weight:600;
}


/* for login page */
.create_accountinfo p{
    font-size: 12px;
    text-align: center;
    color: #767676;
    margin-top: 20px;
    font-weight: 600;
}

.create_accountinfo button{
    background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
    height: 29px;
    width: 300px;
    border-radius: 3px;
    border: 1px solid #adb1b8;
    outline: none;
    margin-top: 15px;
}

.create_accountinfo a{
    text-decoration: none;
    color: #111;
    padding: 10px 35px;
    letter-spacing: .5px;
}

